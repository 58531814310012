// Back enum: update enum PrintDocumentType.php
export enum PrintDocumentType {
  ATTESTATION = 'attestation',
  CONVOCATION = 'convocation',
  CONVOCATION_HA = 'convocation_hybrid_academy',
  CONVENTION = 'convention',
  EMARGEMENT = 'emargement',
  EVALUATION = 'evaluation',
  MATERIALS = 'preperation_materials',
  FLYLEAF = 'flyleaf',
  CERTIFICATE_COMPLETION = 'certificate_completion',
}
