





























































import { mapActions, mapState } from 'vuex'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { defineComponent, inject } from '@vue/composition-api'
import { trainingSessionDocument } from '@/api/interfaces/trainingSessionDocument'
import { LogisticSession } from '@/api/interfaces/logisticSession'
import { PrintDocumentType } from '@/api/enums/printDocumentType'
import { Trainee } from '@/api/interfaces/trainee'

export default defineComponent({
  setup () {
    const apiUrl = inject('getApiUrl')
    return {
      apiUrl,
    }
  },
  props: {
    trainingSession: {
      type: Object as () => TrainingSession,
    },
    logisticSession: {
      type: Object as () => LogisticSession,
    },
    typeDocument: {
      type: String as () => PrintDocumentType,
      required: true,
    },
  },
  name: 'v-dialog-document-actions',
  data () {
    return {
      refreshTimeoutId: 0,
    }
  },
  computed: {
    ...mapState('DocumentGenerate', {
      percentage: 'percentage',
      idSessionDocuments: 'idSessionDocuments',
      idTrainingSessionDocumentsGenerated: 'idTrainingSession',
      typeDocumentGenerated: 'typeDocument',
      mentionClimat: 'mentionClimat',
    }),
    lastTrainingSessionDocument (): trainingSessionDocument | null {
      const trainingSessionDocuments = (this.trainingSession as TrainingSession).trainingSessionDocuments
      if (trainingSessionDocuments && trainingSessionDocuments.length > 0) {
        let lastTrainingSessionDocument: trainingSessionDocument | null = null

        for (const trainingSessionDocument of trainingSessionDocuments) {
          if (trainingSessionDocument.type === this.typeDocument) {
            if (!lastTrainingSessionDocument || (trainingSessionDocument.id && lastTrainingSessionDocument.id && +trainingSessionDocument.id > +lastTrainingSessionDocument.id)) {
              lastTrainingSessionDocument = trainingSessionDocument
            }
          }
        }

        return lastTrainingSessionDocument
      }
      return null
    },
  },
  destroyed () {
    window.clearTimeout(this.refreshTimeoutId)
  },
  methods: {
    ...mapActions('DocumentGenerate', {
      launchGenerateDocument: 'launchGenerateDocument',
      getAdvancement: 'getAdvancement',
    }),
    startGenerateDocument () {
      const body: {
        mentionClimat?: number[],
      } = {}

      if (this.typeDocument === PrintDocumentType.ATTESTATION) {
        body.mentionClimat = this.mentionClimat.map((trainee: Trainee) => trainee.id)
      }

      this.launchGenerateDocument({
        idSession: (this.trainingSession as TrainingSession).id,
        idLogisticSession: (this.logisticSession as LogisticSession).id,
        typeDocument: this.typeDocument,
        body: body,
      }).then(r => {
        this.checkAdvancement(r)
        this.$emit('openAdvancementDialog', true)
      })
    },
    async checkAdvancement (idSessionDocuments: string) {
      await this.getAdvancement(idSessionDocuments)
      if (this.percentage === 100) {
        window.setTimeout(async () => {
          this.$emit('openAdvancementDialog', false)
        }, 2000)
        return
      }
      window.clearTimeout(this.refreshTimeoutId)
      this.refreshTimeoutId = window.setTimeout(async () => {
        await this.checkAdvancement(idSessionDocuments)
      }, 1000)
    },
  },
})
